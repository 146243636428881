@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
:root{
  --main-color: #4ac75c;
  --black: #333;
  --white: #fff;
  --light-color: #666;
  --border: .2rem solid var(--black);
  --box-shadow: 0 .5rem 1rem rgb(0,0,0,.1);
}

.Review{

}

.Review .items{
  background-color: #fff;
  padding: 15px 27px;
  border-radius: 13px;
}

.Review .review{
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Review .text h1{
  font-size: 1.3rem;
  font-weight: 500;
}

.Review .rate{
  margin: .5rem 0;
}

.Review .rate i{
  margin-right: 2px;
  color: #eee10b !important;
}

.Review .rate label{
  margin-left: 6px;
}

.coursesCard {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  flex: 1 1 20rem;
  margin-bottom: 20px;
}
.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--main-color);
  padding: 15px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-transform: uppercase;
}
.coursesCard .rate {
  margin: 20px 0;
  color: var(--main-color);
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard .details span {
  color: var(--main-color);
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: var(--main-color);
}

.introduce{
  background-size: 250px;
  background-image: url("../../../public/images/courses/bg_introduce.png");
  background-repeat: no-repeat;
  margin-top: 3rem;
}

.content2 .des{
  background-size: 250px;
  background-image: url("../../../public/images/courses/bg_introduce.png");
  background-repeat: no-repeat;
  background-position: right;
}


.introduce  h1 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;

}

.introduce p{
  font-size: 18px;
  text-align: justify;
}

.introduce p strong{
  font-size: 19px;
  text-transform: uppercase;
}
.introduce .description{
  display: flex;
  margin-left: 8rem;
}
.introduce .content2{
  display: flex;
  margin-top: 5rem;
}

.introduce .images img{
  height: 15rem;
  animation: moveUpDown 2s infinite alternate;
}

@keyframes moveUpDown {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-20px);
  }
}

.introduce .content2 .des p{
  text-align: justify;
  padding-left: 30px;
  padding-bottom: 15px;
}

.introduce-mar{
  margin-top: 5rem;
}

.box-container1{
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 30px;
  background-size: 250px;
  background-image: url("../../../public/images/courses/bg_introduce.png");
  background-repeat: no-repeat;
  background-position: left;
}

.introduce-mar .box-container1 .box1{
  padding: 2rem;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0,0,0,.1);
  border: 0.1rem solid rgb(0,0,0,.3);
  text-align: center;
  border-radius: 0.5rem;
  flex: 1 1 20rem;
  position: relative;
}

.introduce-mar h1 strong{
  font-size: 1.8rem;
  color: #850606;
  font-weight: 600;
  margin-right: 0.5rem;
}

.box-container1 .box1 h3{
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 1.3rem;
}


/*-------------customer---------*/
.customer {
  text-align: center;
}
.customer .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 20px 10px;
  transition: 0.5s;
}

.customer .heading{
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}
.customer .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}
.customer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customer h1 {
  font-weight: 500;
  font-size: 15px;
  margin: 20px 0;
  line-height: 25px;
}
.customer span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: var(--main-color);
  border-radius: 5px;
}
.customer .box .show {
  opacity: 0;
}
.customer .box:hover {
  background-color: var(--main-color);
  border-radius: 5px;
  cursor: pointer;
}
.customer .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.customer .box:hover h1 {
  color: #fff;
}

.introduce .content2 .images{
  display: flex;
  align-items: center;
  margin-top: 5rem;
}

@media screen and (max-width: 1190px) {
  .Review h2{
    font-size: 1.6rem;
  }

  .coursesCard h1 {
    font-size: 22px;
    line-height: 33px;
  }

  .Card_mobile{
    display: block;
  }

  .introduce p{
    font-size: 17px;
  }

  .introduce .content2{
    display: block;
  }

  .introduce .content2 .images{
    justify-content: center;
    margin-bottom: 2rem;
  }

  .introduce h1{
    font-size: 1.4rem;
  }

  .box-container1 .box1 h3{
    font-size: 1.1rem;
  }
}


@media screen and (max-width: 768px) {
  .Review h2{
    font-size: 1.3rem;
  }

  .coursesCard h1{
    font-size: 1.2rem;
  }

  .Review .text h1{
    font-size: 1.1rem;
  }

  .introduce h1{
    font-size: 1.3rem;
  }

  .introduce p{
    font-size: 15px;
    line-height: 25px;
    margin-left: 0 !important;
  }

  .introduce .images img{
    height: 12rem;
  }

  .customer .heading{
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 467px) {
  .Review h2{
    font-size: 1.1rem;
  }

  .coursesCard  h1{
    font-size: 0.9rem;
  }


  .outline-btn{
    font-size: 0.8rem;
  }

  .para h4{
    font-size: 0.8rem;
  }

  .Review .text h1{
    font-size: 1rem;
  }

  .introduce h1{
    font-size: 1.1rem;
  }

  .introduce p{
    font-size: 15px;
    line-height: 25px;
  }

  .introduce .images img {
    height: 10rem;
  }

  .box-container1 .box1 h3{
    font-size: 1rem;
  }

  .customer .heading{
    font-size: 1.1rem;
  }


}

@media screen and (max-width: 320px) {
  .outline-btn{
    font-size: 0.7rem;
  }
}

.slider-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 600px; /* Set the desired width for your slider */
  margin: 0 auto;
}

.slick-prev:before, .slick-next:before{

  color: rgba(0,0,0,0.5) !important;
}

.slick-slide div:first-child{
  margin-right: 20px !important;
}

.slick-dots li button{
  display: none !important;
}