:root{
  --main-color: #4ac75c;
  --black: #333;
  --white: var(--white);
  --light-color: #666;
  --border: .2rem solid var(--black);
  --box-shadow: 0 .5rem 1rem rgb(0,0,0,.1);
} 

.blog h2{
  font-size: 29px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 2px black solid;
  text-align: center;
  margin: -30px 0 30px 0;
}



.blog img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}
.blog .text {
  padding: 40px 30px;
}
.blog h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
}
.blog h1:hover {
  color: var(--main-color);
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--main-color);
}
.blog span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.blog span:nth-last-child(1) label {
  color: var(--main-color);
}
.blog p {
  color: grey;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product--image {
  width: 100%;
  height: 16em;
  object-fit: cover;
}

.Hblog {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 320px;
  margin: auto 1rem;
  text-align: center;
  font-family: arial;
  margin-bottom: 50px;
  padding-bottom: 5px;
}

.Hblog .product--image:hover{
  transform: scale(1.1);
  cursor: pointer;
  transition: 0.3s linear;
}

.card button:hover {
  opacity: 0.7;
}

.Hblog h2{
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Hblog .admin span i{
  margin-right: 7px;
}

.Hblog .admin span label{
  font-size: .7rem;
}

.Hblog p{
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Hblog .admin{
  display: flex;
  justify-content: space-around;
  margin: 12px 0 6px 0;
}

.Hblog .read_more{
  font-size: 0.9rem;
}

.Hblog .read_more {
  color: #1f1f1f;
  font-weight: 600;
}
.color-text{
  color: #1f1f1f;
}
.Hblog .read_more:hover{
  text-decoration: underline;
  color: #a14141;
}

.title-blog{
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 2px black solid;
  text-align: center;
  max-width: 85%;
  margin: auto;
  margin-bottom: 35px;
  margin-top: 35px;
}

.react-multiple-carousel__arrow--right{
  padding: 0;
}

.react-multiple-carousel__arrow--left{
  padding: 0;;
}


@media screen and (max-width: 1380px) {

}

@media screen and (max-width: 1190px) {

}


@media screen and (max-width: 768px) {
  .title-blog{
    font-size: 1.3rem;
  }

  .Hblog h2{
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 467px) {
  .title-blog{
    font-size: 1.3rem;
  }

  .Hblog h2{
    font-size: 1rem;
  }
}

