/*--------head------------*/

:root{
  --main-color: #4ac75c;
  --black: #333;
  --white: #fff;
  --light-color: #666;
  --border: .2rem solid var(--black);
  --box-shadow: 0 .5rem 1rem rgb(0,0,0,.1);
}
a{
  color: #615757;
}
a:hover{
  color: #1f1f1f;
}

.head {
  padding: 0 0 4px 0;
  color: var(--white);
}

.head .container{
  text-align: center;
  align-items: center;
}

.head .logo img{
  width: 160px;
  object-fit: cover;
  margin-bottom: 10px;
}

.head .logo img:hover{
  -webkit-transform: scale(1.2);
  transform: scale(1.1);
  transition: 0.2s all linear;
}
.logo a {
  font-size: 1.7rem;
  letter-spacing: 2px;
  font-weight: 500;
  color: #e15d5d;
}

.logo a:hover{
  color: #d03b3b;
  letter-spacing: 2.1px;
  transition: .2s all linear;
}

.head .icon {
  margin-left: 10px;
}

/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgb(178 178 178 / 20%);

}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
  font-weight: 500;
  color: #850606;
}

header li:hover{
  color: #c50000;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #615757;
  font-weight: 500;
  transition: 0.5s;
}

header ul li:hover{
  cursor: pointer;
}

header ul li a:hover {
  color: var(--main-color);
}
.start {
  background-color: var(--main-color);
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 1380px) {
  
  header li {
    margin-right: 30px;
  }

  header ul li a{
    font-size: 1rem;
  }

  .logo a{
    font-size: 1.5rem;
  }

  .head .logo img{
    width: 100px;
  }

}

@media screen and (max-width: 1190px) {

}


@media screen and (max-width: 768px) {


  .logo a{
    font-size: 1.2rem;
  }

  .head .logo img{
    width: 80px;
  }

  header ul li a{
    color: black;
  }

  header ul li a:hover{
    color: #ca4444;
    letter-spacing: 1px;
  }

  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: var(--main-color);
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
    background: #ffffff;
    text-align: center;
    color: black;
    padding: 8px 0;
    border-radius: 35px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
  }
  .mobile-nav {
    position: absolute;
    z-index: 1;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: var(--main-color);
    transition: .5s linear;
  }



}


@media screen and (max-width: 467px) {
  .head .logo img{
    width: 70px;
  }

  header ul li{
    padding: 5px 0;
  }

  header ul li a{
    font-size: 0.8rem;
  }

  .head .logo a{
    font-size: 1rem;
  }
  .social{
    text-align: center;
  }

  .social a i{
    margin-top: 4px;
  }


  .head .social {
    display: none;
  }

  .head .container{
    justify-content: center;
  }

  .head .container .logo{
    margin: 6px 0;
  }
}