.hero {
  background-image: url("../../../../public/images/bg.png");
  background-size: cover;
  width: 100%;
  height: 50rem;
  padding-right: 50px;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {


  .hero .row {
    width: 100%;
  }

}

@media screen and (max-width: 467px) {
  .hero{
    height: 40rem;
  }
}