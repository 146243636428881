:root{
  --main-color: #4ac75c;
  --black: #333;
  --white: var(--white);
  --light-color: #666;
  --border: .2rem solid var(--black);
  --box-shadow: 0 .5rem 1rem rgb(0,0,0,.1);
} 



.testimonal .items {
  padding: 30px;
  transition: 0.5s;
  margin-bottom: 20px;
  border-radius: 20px;
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.testimonal img:hover{
  transform: scale(1.1);
}
.testimonal i {
  margin-right: 0.5rem;
  color: var(--main-color);
}
.testimonal .name p{
  margin-top: 10px;
}

.testimonal .name {
  margin: 20px;
}
.testimonal .name h2 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  border: none;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
}
.testimonal .name span {
  color: var(--main-color);
}

.testimonal .img i{
  background-color: var(--main-color);
  bottom: 0;
  color: #fff;
  left: 55px;
  top: 80px;
  position: absolute;
}

.testimonal .img i:hover{
  color: var(--main-color) !important;
  background-color: #fff !important;
}

.testimonal p {
  margin-top: 20px;
  color: #000000;
  transition: 0.5s;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.testimonal .items .box{
  height: 8rem;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: var(--white);
}


/*----------awrapper------------*/
@media screen and (max-width: 1190px) {
  .testimonal .container .grid2{
    display: block;
  }

  .testimonal h2{
    font-size: 1.6rem;
  }


}

@media screen and (max-width: 768px) {
  .testimonal h2{
    font-size: 1.3rem;
  }

  .testimonal .name h2{
    font-size: 1.2rem;
  }

  .testimonal img{
    width: 90px;
    height: 90px;
  }

  .testimonal .img i{
    top: 67px;
  }
}

@media screen and (max-width: 467px) {
  .testimonal h2{
    font-size: 1.1rem;
  }

  .testimonal .name h2{
    font-size: 0.9rem;
  }

  
  .testimonal p{
    margin-top: 10px;
  }

  .testimonal .name span{
    font-size: 0.8rem;
  }

  .testimonal .img i{
    width: 35px;
    height: 35px;
    line-height: 35px !important;
  }
}