:root {
  --main-color: #4ac75c;
  --black: #333;
  --white: #fff;
  --light-color: #666;
  --border: .2rem solid var(--black);
  --box-shadow: 0 .5rem 1rem rgb(0, 0, 0, .1);
}

.detail {
  color: #1f1f1f;
  font-weight: 600;
}

.detail:hover {
  text-decoration: underline;
  color: #a14141;
}

.fa-star {
  color: gray !important;
}

.fa-star.active {
  color: gold !important;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  text-align: center;
}

.box-container-commit {
  margin-top: 40px;
}

.box-container {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 1.5rem;
  background-color: #6eb178;
  padding: 30px;
}

.padding {
  padding: 40px 0;
}

.box-container .box {
  padding: 2rem;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0, 0, 0, .1);
  border: 0.1rem solid rgb(0, 0, 0, .3);
  text-align: center;
  border-radius: 0.5rem;
  position: relative;
}

.box-container-commit .heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 2px black solid;
  text-align: center;
  max-width: 85%;
  margin: auto;
  margin-bottom: 30px;
}

.box-container .box img {
  height: 5rem;
  object-fit: cover;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
}

.box-container .box p{
  text-align: justify;
}

img:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: 0.3s linear;
}

.box-container .box h1 {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin: .5rem 0;
  font-weight: 500;
}

footer .box .flexSB_footer {
  display: flex;
  justify-content: start;
}

footer {
  background-color: #eeeeee;
}

footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr 4fr 3fr;
  grid-gap: 30px;
}

@media screen and (max-width: 1144px) {


  .box-container{
    grid-template-columns: repeat(2,1fr);
  }
}

@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media screen and (max-width: 500px) {
  .box-container {
    grid-template-columns: repeat(1, 1fr);
  }

}

footer .logo img {
  height: 150px;
  object-fit: contain;
  text-align: left;
  margin-bottom: 15px;
}

footer .logo span {
  color: var(--main-color);
  font-size: 14px;
}

footer .logo .icon {
  background-color: #8d9f90;
  color: var(--white);
  margin-right: 10px;
}

footer .logo .icon:hover {
  background-color: var(--main-color);
}

footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
  text-transform: uppercase;
}

footer .link ul li {
  margin-bottom: 20px;
  color: #850606;
}

footer .link ul li:hover {
  cursor: pointer;
  color: #ff4d2f
}

footer .link li::before {
  content: "⟶";
  color: var(--black);
  margin-right: 5px;
}

footer .items {
  margin-bottom: 30px;
}

footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

footer .items span,
footer .items i {
  font-size: 12px;
  color: var(--black);
  margin-right: 5px;
  text-transform: capitalize;
}

footer h4 {
  font-weight: 400;
  margin-top: 5px;
}

footer h4:hover {
  cursor: pointer;
  text-decoration: underline;
}

footer .last ul li {
  display: flex;
  margin-bottom: 30px;
  text-align: justify;
}

footer .last i {
  font-size: 20px;
  color: var(--black);
}

footer .last i::before {
  margin-right: 20px;
}

footer .hotline-phone-ring {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 999999;
}

footer .hotline-phone-ring .hotline-phone {
  position: relative;
  visibility: visible;
  background-color: transparent;
  width: 110px;
  height: 110px;
  z-index: 11;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  left: 0;
  bottom: 0;
  display: block;
}

footer .hotline-phone-ring .hotline-phone .phone-circle {
  width: 85px;
  height: 85px;
  top: 10px;
  left: 10px;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #c92e2e;
  -webkit-animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: .5;
}

footer .hotline-phone-ring .hotline-phone .phone-ring-fill {
  width: 55px;
  height: 55px;
  top: 25px;
  left: 25px;
  position: absolute;
  background-color: rgba(241, 3, 3, .57);
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

footer .phone-img {
  background-color: #c92e2e;
  width: 33px;
  height: 33px;
  top: 37px;
  left: 37px;
  position: absolute;
  background-size: 20px;
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes phonering-alo-circle-img-anim {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes phonering-alo-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(.5) skew(1deg);
    -webkit-opacity: .1;
  }

  30% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    -webkit-opacity: .5;
  }

  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    -webkit-opacity: .1;
  }
}

footer .phone-img .p-img {
  display: flex;
}

footer .hotline {
  position: absolute;
  background: rgba(241, 3, 3, .57);
  height: 40px;
  width: 180px;
  line-height: 40px;
  border-radius: 3px;
  border-radius: 50px !important;
  padding: 0 10px;
  background-size: 100%;
  z-index: 9;
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .1);
  left: 33px;
  bottom: 37px;
}

footer .hotline a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  text-indent: 50px;
  display: block;
  letter-spacing: 1px;
  line-height: 40px;
  font-family: Arial;
}



.legal {
  text-align: center;
  padding: 50px 0;
  color: grey;
  background-color: #e9e9e9;
}

.legal p {
  font-size: 15px;
}

.legal i {
  color: var(--main-color);
}

.secSupport__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  right: 20px;
  bottom: calc(25vh - 150px);
  z-index: 999;
}

.secSupport__social a {
  margin-bottom: 20px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

.phone {
  animation: shake 0.3s infinite;
}

.phone.stop {
  animation: none;
}

.feedback{
  margin-top: 40px;
}
.feedback .heading {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 2px black solid;
  text-align: center;
  max-width: 85%;
  margin: auto;
  margin-bottom: 35px;
  margin-top: 35px;
}

.feedback .row {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  border-radius: .5rem;
  width: 100%;
}

.feedback .row .image {
  flex: 1 1 30rem;
}

.feedback .row .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: .5rem;
}

img:hover {
  transform: none;
  cursor: pointer;
  transition: none;
}

.feedback .row form {
  flex: 1 1 25rem;
  padding: 1rem;
}

.feedback .row form .inputBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.feedback .row form .inputBox input,
.feedback .row form textarea {
  padding: 0.8rem;
  margin: 1rem 0;
  font-size: 1rem;
  color: #333;
  text-transform: none;
  border: 0.15rem solid rgb(0, 0, 0, .3);
  border-radius: 0.5rem;
  width: 100%;

}

.feedback form .rate i {
  color: #eee10b;
  font-size: 1.3rem;
  letter-spacing: 5px
}

.feedback form .rate i:hover {
  cursor: pointer;
  color: #dfd314;
}

.feedback .row form textarea {
  width: 100%;
  resize: none;
  height: 15rem;
}

.feedback .row form .btn {
  padding: 1rem 33px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #46be58;
  color: #fff;
  border: none;
  border-radius: 10px;
}

.feedback .row form .btn:hover {
  cursor: pointer;
  background-color: #4bb85b;
  transition: .2s linear;
  font-size: 1.1rem;
}

.feedback .image img{
  width: 30rem;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 1160px) {
  footer .logo img {
    height: 135px;
  }

  .feedback .row .image{
    display: flex;
    justify-content: center;
  }

  .feedback .row .image img{
    width: 75%;
  }

}

@media screen and (max-width: 768px) {
  .secSupport__social {
    bottom: calc(25vh - 200px);
  }

  .secSupport__social a {
    margin-bottom: 10px;
  }

  .box-container-commit .heading {
    font-size: 1.3rem;
  }


  .feedback .heading {
    font-size: 1.3rem;
  }

  footer .logo img {
    height: 125px;
  }

  footer .icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  footer h3 {
    margin-bottom: 20px;
  }

  .secSupport__social img {
    width: 2.7rem;
    height: auto;
  }

  footer .hotline-phone-ring .phone-img img {
    width: 2.7rem;
    height: auto;
  }

  .feedback .row .image img{
    width: 100%;
  }
}


@media screen and (max-width: 467px) {
  footer .container {
    display: block;
  }

  .box-container-commit .heading {
    font-size: 1.1rem;
  }

  .secSupport__social img {
    width: 2.5rem;
    height: auto;
  }

  footer .hotline-phone-ring .phone-img img {
    width: 2.5rem;
    height: auto;
  }

  footer .hotline-phone-ring .hotline-phone .phone-ring-fill {
    width: 50px;
    height: 50px;
    top: 28px;
    left: 28px;
  }

  footer .logo {
    text-align: center;
  }

  footer .link {
    text-align: center;
    margin: 20px;
  }

  .feedback .heading {
    font-size: 1.1rem;
  }

  .feedback .row form .btn {
    padding: 0.8rem 25px;
  }

  .box-container .box img{
    height: 4rem;
  }
}

