.singlePage {
    padding: 50px 0;
    background: #fff;
  }
  .singlePage .right {
    margin-top: 30px;
  }
  .singlePage img {
    width: 70%;
    height: 100%;
    border-radius: 10px;
  }

  .singlePage img:hover{
    transform: none;
    cursor: pointer;
    transition: none
  }

  .singlePage h1 {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
  }
  .singlePage p {
    margin: 20px 0;
    font-size: 18px;
    line-height: 2;
    text-transform: capitalize;
  }

  .itemHeader p{
    text-align: center;
  }
  .singlePage .buttons {
    display: flex;
    justify-content: flex-end;
  }
  .singlePage .button {
    margin-left: 20px;
    border: none;
  }
  .singlePage .button:nth-child(1) {
    background: #10ac84;
  }
  .singlePage .button:nth-child(2) {
    background: #ee5253;
  }
  
@media screen and (max-width: 1190px)
{
  .singlePage .right h1 {
      font-size: 28px;
  }

  .singlePage .itemTitle {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 467px)
{
  .singlePage .right h1 {
      font-size: 22px;
  }

  .singlePage .itemTitle {
    font-size: 1rem;
  }
}