:root{
  --main-color: #4ac75c;
  --black: #333;
  --white: #fff;
  --light-color: #666;
  --border: .2rem solid var(--black);
  --box-shadow: 0 .5rem 1rem rgb(0,0,0,.1);
} 

.ServiceHome {
  height: auto;
  margin-top: 2rem;
}

.ServiceHome .flexSB{
  align-items: center;
}

.ServiceHome #heading {
  text-align: left;
  padding: 0;
}

.ServiceHome .iconS{
  text-align: center;
  margin-bottom: 1rem;
}

.ServiceHome .iconS i{
  background: #E05D5D;
  padding: 20px;
  border-radius: 50%;
  font-size: 40px;
  color: #fff;
  margin-right: 20px;
}




.ServiceHome .right {
  padding: 80px 50px;
}
.ServiceHome .items {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;

}
.ServiceHome .item {
  background-color: var(--white);
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
  flex: 1 1 10rem;
  margin-bottom: 3rem;
}

.ServiceHome .item:nth-child(2){
  border-right: 2px solid black;
  border-left: 2px solid black;
}

.ServiceHome .item:hover{
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);

}
.ServiceHome .img {
  text-align: center;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

.ServiceHome img {
  width: 70px;
  height: 70px;
  animation: shake 2s;
}
.ServiceHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
}
.ServiceHome .item p {
  color: #999999;
  text-align: justify;
}
.ServiceHome .item:hover {
  color: var(--white);
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.ServiceHome .item:hover p {
  color: var(--white);
}



/*----------RESPONSIVE------------*/
@media screen and (max-width: 1380px) {
 

} 

@media screen and (max-width: 1190px) {
  p{
    line-height: 27px;
    font-size: 18px;
  }



  .ServiceHome .title{
    font-size: 1.5rem;
  }

  .ServiceHome img{
    width: 65px;
    height: 65px;
  }

  .ServiceHome .item h2{
    font-size: 1.3rem;
  }

  .ServiceHome .items{
    margin-top: 30px;
  }

  .ServiceHome img {
    width: 60px;
    height: 60px;
  }
}


@media screen and (max-width: 768px) {
  .ServiceHome .container {
    flex-direction: column-reverse;
  }
  .ServiceHome .row {
    width: 100%;
  }
  .ServiceHome .items{
    margin-top: 0;
  }

  .ServiceHome .item{
    flex: 1 1 20rem;
  }

  .ServiceHome .title{
    font-size: 1.3rem;

  }

  .ServiceHome .item h2{
    font-size: 1.2rem;
  }


  p {
    line-height: 23px;
    font-size: 15px;
  }

  .ServiceHome .item{
    border-right: 2px solid black;
    border-left: 2px solid black;
  }

}

@media screen and (max-width: 467px) {
  .ServiceHome .title {
    font-size: 1.1rem;
}

  p {
    line-height: 22px;
    font-size: 13px;
  }
}

@media screen and (max-width: 375px) {

}
