#notfound {
  position: relative;
  height: 80vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1
}

.notfound .notfound-404 h1 {
  font-family: maven pro,sans-serif;
  color: #e2dfdf;
  font-weight: 900;
  font-size: 276px;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

.notfound h2 {
  font-family: maven pro,sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0
}

.notfound p {
  font-family: maven pro,sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin: 15px 0;
}

.notfound a {
  font-family: maven pro,sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: .2s all;
  transition: .2s all
}

.notfound a:hover {
  background-color: #fff;
  border-color: #189cf0;
  color: #189cf0
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
      font-size:162px
  }

  .notfound h2 {
      font-size: 26px
  }
}


.st0{fill:#fff}
.st2{fill:#5d89af}
.st3{fill:#709abf}
.st4,.st6{
  fill:#fff;
  stroke:#b3dcdf;
  stroke-miterlimit:10
}
.st6{
  stroke:#5d89af;
  stroke-width:2
}
.st7,.st8,.st9{
  stroke:#709abf;
  stroke-miterlimit:10
}

.st7{
  stroke-width:5;
  stroke-linecap:round;
  fill:none
}
.st8,.st9{
  fill:#fff
}
.st9{
  fill:none
}
.st10{
  
}

#cloud1{
  animation: cloud003 15s linear infinite;
}

#cloud2{
  animation: cloud002 25s linear infinite;
}

#cloud3{
  animation: cloud003 20s linear infinite;
}

#cloud4{
  animation: float 4s linear infinite;
}

#cloud5{
  animation: float 8s linear infinite;
}

#cloud7{
  animation: float 5s linear infinite;
}

#tracks{
  animation: slide 650ms linear infinite;
}

#bumps{
  animation: land 10000ms linear infinite;
}

@keyframes jig {
    0% { transform: translateY(0px); }
    50% { transform: translateY(1px); }
    100% { transform: translateY(0px); }
}

#car-layers{
  animation: jig 0.35s linear infinite;
}

@keyframes land {
    from { transform: translateX(0); }
    to { transform: translateX(1000px); }
}


@keyframes slide {
    from { transform: translateX(0px); }
    to { transform: translateX(100px); }
}

/* @keyframes cloudFloat {
  0% { transform: translateX(0) translateY(3px); }
  100% { transform: translateX(1000px) translateY(0); }
} */

@keyframes cloud001 {
  0% { transform: translateX(-1000px) translateY(3px); }
  100% { transform: translateX(1000px) translateY(0); }
}

@keyframes cloud002 {
  0% { transform: translateX(-1000px) translateY(3px); }
  100% { transform: translateX(1000px) translateY(0); }
}

@keyframes cloud003 {
  0% { transform: translateX(-1000px) translateY(3px); }
  100% { transform: translateX(1000px) translateY(0); }
}

@keyframes float {
    0% { transform: translateY(0px) translateX(0); }
    50% { transform: translateY(8px) translateX(5px); }
    100% { transform: translateY(0px) translateX(0); }
}

#bracefront, #braceback{
  animation: braces 1s linear infinite;
}

@keyframes braces {
    0% { transform: translateX(-2px); }
  25% { transform: translateX(3px); }
    50% { transform: translateX(-2px); }
    75% { transform: translateX(3px); }
  100% { transform: translateX(-2px); }
}

@media screen and (max-width: 1190px) {
  .notfound h2{
    font-size: 40px;
  }

  .notfound p{
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .notfound h2{
    font-size: 35px;
  }

  .notfound p{
    font-size: 13px;
  }

  .notfound a {
    font-size: 12px;
    padding: 14px 29px;
  }
}

@media screen and (max-width: 467px) {
  .notfound h2{
    font-size: 30px;
  }

  .notfound a {
    font-size: 11px;
    padding: 13px 25px;
}
.notfound .notfound-404 h1 {
  font-size: 140px;
}
}