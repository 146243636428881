@media screen and (max-width: 768px) {
  .title-blog{
    font-size: 1.3rem;
  }

  .Hblog h2{
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 467px) {
  .title-blog{
    font-size: 1.3rem;
  }

  .Hblog h2{
    font-size: 1rem;
  }

  .policy p span{
    font-size: 15px !important;
  }
}

.singlePage {
  padding: 50px 0;
  background: #fff;
}
.singlePage .right {
  margin-top: 30px;
}

.singlePage img:hover{
  transform: none;
  cursor: pointer;
  transition: none
}

.singlePage h1 {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
}
.singlePage p {
  font-size: 18px;
  line-height: 2;
  text-transform: capitalize;
}

.itemHeader p{
  text-align: center;
}
.singlePage .buttons {
  display: flex;
  justify-content: flex-end;
}
.singlePage .button {
  margin-left: 20px;
  border: none;
}
.singlePage .button:nth-child(1) {
  background: #10ac84;
}
.singlePage .button:nth-child(2) {
  background: #ee5253;
}

.policy h4{
  font-size: 1.3rem;
  text-transform: uppercase;

}

.policy h3{
  background-color: #fff !important;
}

.policy h2{
  background-color: #fff !important;
  
}

.policy p{
  background-color: #fff !important;
  
}

.policy h1{
  background-color: #fff !important;

}

.policy h2 span{
  font-size: 1.2rem;
}

.policy h3 span{
  font-size: 1.15rem;
}

.policy p span{
  font-size: 17px;
}

.policy ul {
  background-color: #fff !important;
}

.policy ul li{
  list-style-type: none !important;
  margin-top: 0.2rem;
}

.policy p{
  margin: 2px 0;
}

.policy h3{
  margin-top: .5rem;
}


strong{
  font-size: 1rem;
}

.policy ul li span{
  margin: 2px 0;
  line-height: 1.8;
}

.policy ul li span span{
  font-size: 17px;
}