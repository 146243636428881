@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
:root{
  --main-color: #4ac75c;
  --black: #333;
  --white: #fff;
  --light-color: #666;
  --border: .2rem solid var(--black);
  --box-shadow: 0 .5rem 1rem rgb(0,0,0,.1);
} 

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background: var(--main-color);
}

.section-title{
  text-align: center;
  margin-bottom: 3.8rem;
  margin-top: 3rem;
}

.title-line{
  width: 100px;
  height: 3px;
  border-radius: 2px;
  display: inline-block;
  background-color: #E05D5D;
}

.title{
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
}

.box-animation{
  background-color: #03943F;
  width: 100%;
}

.box-animation .box{
  width: 100%;
  margin-bottom: 30px;
}

.box-animation .box .marquee{
  text-align: left;
  padding: .7rem 0;
  color: yellow;
  white-space: nowrap;
  font-size: 1.3rem;
}

.container {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50%;
  text-align: center;
  background-color: rgb(0 0 0 / 30%);
  color: #1f1f1f;
  transition: 0.3s;
}

.social{
  text-align: left;
}

.social p{
  margin-top: 10px;
  color: #fff;
  
}

.social p:hover{
  color: var(--main-color);
}

.icon:hover {
  cursor: pointer;
  background-color: #7ddb8a;
  color: #fff;
}

.head .information{
  text-align: left;
}

.head .information p{
  margin-bottom: .5rem;
  color: #fff;
  padding: 10px 0;
}

.head .information p:hover{
  text-decoration: underline;
  cursor: pointer;
}

.head .information p i{
  margin-right: .5rem;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 50%;
}
button {
  padding: 17px 30px;
  background-color: var(--white);
  color: var(--main-color);
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color:  var(--main-color);
  color: var(--white);
  border-radius: .5rem;
}

.primary-btn:hover{
  background-color:  #459951;
  cursor: pointer;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color:  var(--main-color);
  text-transform: uppercase;
}
#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}
p {
  line-height: 30px;
  font-size: 18px;
}

.back {
  background-image: url("../public/images/bg.png");
  background-size: cover;
  width: 100%;
  height: 40rem;
  padding-right: 50px;
  color: #fff;
}

.Container{
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: space-evenly;
}

.Container .text-left{
  width: 45%;
}

.Container .text-left h1{
  font-size: 3rem;
  color: #fff;
}

.Container .text-left p{
  font-size: 2rem;
  color: #fff;
}

.text-right{
  width: 55%;
  display: grid;
}

.text-right .text-box{
  margin-left: 25rem;
  margin-bottom: 1rem;
}

.text-right .text-box h3{
  color: #fff;
  font-size: 2rem;
  animation: slide 4s linear infinite;;
}

.text-right .text-box p{
  font-size: 1.3rem;
  color: #fff;
  box-shadow: 0 .5rem 1rem rgb(0,0,0,.5);
  animation: slide 4s linear infinite;
}
.back .text-left h1 {
  font-size: 5rem;
  font-weight: 400;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin {
  margin-top: 38%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid  var(--main-color);
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color:  var(--main-color);
  color: var(--white);
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin-bottom: 40px;
}

.shadow {
  box-shadow: 0 5px 25px -3px rgb(0 0 0 / 6%);
  background-color: var(--white);
}


@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


.border{
  background: black;
  padding: 1px;
  margin: 1rem 40rem 1.5rem 40rem;
  text-align: center;
}



@media screen and (max-width: 1380px) {
  .box-animation .box .marquee{
    font-size: 1.1rem;
  }

  .margin{
    margin-top: 100%;
  }

}

@media screen and (max-width: 1190px) {
  .box-animation .box .marquee{
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
  .margin{
    margin-top: 103%;
  }

  .title{
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 610px) {
  .margin {
    margin-top: 86% !important;
  }
}




@media screen and (max-width: 467px) {
  .box-animation .box .marquee{
    font-size: 0.9rem;
  }
  .margin {
    margin-top: 143% !important;
  }
  .title{
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 375px) {
  .margin {
    margin-top: 66% !important;
}
}

@media screen and (max-width: 375px) {
  .margin {
    margin-top: 66% !important;
}
}