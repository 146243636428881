.tab{
 
}

.container1 {

  position: relative;
  width: 90%;
  height: max-content;
  margin: 100px auto 0;
}

.detail {
  color: #1f1f1f;
  font-weight: 600;
}

.detail:hover {
  text-decoration: underline;
  color: #a14141;
}

.content-tabs .box-content {
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
}

.content-tabs .box-content img {
  width: 100%;
  height: 14rem;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.box-content-small {
  text-align: center;
  padding: 1.5rem;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0, 0, 0, .1);
  border: 0.1rem solid rgb(0, 0, 0, .3);
  border-radius: 32px;
}

.box-content-small h3 {
  text-transform: uppercase;
  font-size: 1rem;
  margin-top: 1rem;
  word-break: normal;
}

.box-content-small p {
  margin: 6px 0;
  margin-bottom: 15px;
}

.box-content-small .btn {
  border: 1px solid black;
  padding: 0.5rem 1.7rem;
  border-radius: 9px;
  color: black;
  box-shadow: 0 0.5rem 1rem rgb(0, 0, 0, .1);
}

.box-content-small .btn:hover {
  background-color: #4ac75c;
  cursor: pointer;
  transition: 0.5s ease;
  transform: scale(1.1);
}

.bloc-tabs1 {
  gap: 0.8rem;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  margin-bottom: 2rem;
}

.bloc-tabs1 button {
  color: #1f1f1f;
  font-weight: 550;
  font-size: 1rem;
  text-align: center;
}

.tabs {
  flex: 1 1 20rem;
  padding: 15px 5px;
  text-align: center;
  background-image: url("../../../public/images/bg-tab1.webp");
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  background-repeat: no-repeat;
  background-size: cover;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #4ac75c;
}



button {
  border: none;
  margin: 0;
}

.content-tabs {
  flex-grow: 1;
}

.content1 {
  padding: 20px;
  width: 100%;
  display: none;
  margin: 2rem 0 3rem 0;
}

.content1 h2 {
  padding: 0 0 5px 0;
}

.content1 hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.active-content {
  display: block;
}


/*----------awrapper------------*/
@media screen and (max-width: 1190px) {
  h1 {
    font-size: 28px;
  }

  .content-tabs .box-content {
    grid-template-columns: repeat(3,1fr);
  }
}


@media screen and (max-width: 768px) {
  .container1 .title {
    font-size: 1.3rem;
  }

  .bloc-tabs1 button {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .tabs {
    flex: 1 1 13rem;
  }

  .content-tabs .box-content{
    grid-template-columns: repeat(2,1fr);
  }

  .bloc-tabs1 {
    grid-template-columns: repeat(3,1fr);
  }

  
}

@media screen and (max-width: 467px) {
  .container1 .title {
    font-size: 1.1rem;
  }

  .bloc-tabs1 {
    grid-template-columns: repeat(2,1fr);
  }

  .content-tabs .box-content{
    grid-template-columns: repeat(1,1fr);
  }
}

@media screen and (max-width: 340px) {

  .bloc-tabs1 {
    grid-template-columns: repeat(1,1fr);
  }
}